import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';

import { Prev, Next } from './PrevNext';
import All from './All';
import Main from './Main';

const HomePage = ({ homepagePaintings }) => {
  const [flickityRef, setFlickityRef] = useState();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const len = homepagePaintings.length;
  const next = () => {
    setIndex((index + len - 1) % len);
    flickityRef.next();
  };
  const prev = () => {
    setIndex((index + 1) % len);
    flickityRef.previous();
  };

  const categorySlug = homepagePaintings[index].categories.length
    ? homepagePaintings[index].categories[0].slug.current
    : false;

  return (
    <HomeMain className="home">
      <div className="home-slider-wrapper">
        <Flickity
          className="home-slider"
          options={{
            prevNextButtons: false,
            pageDots: false,
            on: {
              change(index) {
                setIndex(index);
              },
              ready() {
                setLoading(false);
              },
            },
            cellSelector: '.home-slide',
            wrapAround: true,
          }}
          flickityRef={c => setFlickityRef(c)}
        >
          {homepagePaintings.map(({ slug, images, title, info, id }) => (
            <HomeSlide
              className="home-slide"
              key={id}
              to={`/painting/${slug.current}`}
            >
              <div className="image-wrapper">
                <Img
                  fluid={images[0].asset.fluid}
                  alt={images[0].alt}
                  objectFit="contain"
                />
              </div>

              <h2 className="painting-title">{title}</h2>

              <PaintingControls className="painting-controls">
                <p className="painting-info">{info}</p>
              </PaintingControls>
            </HomeSlide>
          ))}
        </Flickity>
        <div
          className="home-buttons"
          style={{
            width: '800px',
            position: 'relative',
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Prev
            className="home-buttons__prev"
            onClick={prev}
            style={{
              position: 'absolute',
              left: '2rem',
              bottom: '10rem',
              zIndex: 0,
            }}
          />
          <Next
            className="home-buttons__next"
            onClick={next}
            style={{
              position: 'absolute',
              right: '2rem',
              bottom: '10rem',
              zIndex: 0,
            }}
          />
          <All
            className="home-buttons__all"
            link={`/category/${categorySlug}`}
            style={{ opacity: !!categorySlug ? 1 : 0 }}
          />
        </div>
      </div>
    </HomeMain>
  );
};

const HomeSlide = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: block;
  height: 100%;
  width: 100%;
  padding: 4rem 3.5rem 2rem;
  @supports (display: grid) {
    display: grid;
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 1fr;
    .image-wrapper {
      position: relative;
      /* height: 100%; */
      .gatsby-image-wrapper {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const HomeMain = styled(Main)`
  .image-wrapper {
  }
  .painting-title {
    padding-top: 0;
  }
  .gatsby-image-wrapper {
    top: 0;
    left: 0;
  }

  .painting-link {
    color: inherit;
    text-decoration: none;
  }
  .painting-title {
    padding: 0;
  }
  .home-slider-wrapper {
    position: relative;
    height: 100%;
  }
  .home-slider {
    height: 100%;
    .flickity-viewport {
      /* height: calc(100% - 8.2rem) !important; */
      height: 100% !important;
    }
  }
`;

const PaintingControls = styled.div`
  padding: 0 0 3.5rem;
  position: relative;

  .prev-next-button {
    position: absolute;
    top: 50%;
    transform: translateY(50%);
  }
  .prev {
    left: 2rem;
  }
  .next {
    right: 2rem;
  }
  .prev-next-button {
  }
  .painting-info {
    color: inherit;
    text-decoration: none;
    display: block;
  }
`;

export default HomePage;
