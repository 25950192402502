import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import HomePage from '../components/HomePage';

const IndexPage = ({ data }) => {
  const {
    sanityHomePage: { seoSettings },
  } = data;
  return (
    <Wrapper home className="homepage" seo={seoSettings}>
      <HomePage {...data.sanityHomePage} />
    </Wrapper>
  );
};

export const HomeQuery = graphql`
  query HomeQuery {
    sanityHomePage {
      homepagePaintings {
        id
        title
        slug {
          current
        }
        _key
        info
        images {
          alt
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        categories {
          slug {
            current
          }
        }
      }
      seoSettings {
        metaDescription
        title
      }
    }
  }
`;

export default IndexPage;
